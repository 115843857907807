ul.pagination {
  width: 327px;
  margin: 16px auto;
  justify-content: center;
}
ul.pagination li.page-item.active a.page-link {
  background-color: #e3e7eb !important;
  border-color: #ced4da !important;
}
ul.pagination span.page-link {
  /* padding: 0.75rem 1rem; */
  /* min-width: 3.5rem; */
  text-align: center;
  box-shadow: none !important;
  border-color: #ced4da !important;
  color: #1d1d1d;
  font-weight: 900;
  font-size: 1rem;
}
ul.pagination a.page-link:hover {
  background-color: pink;
}
ul.pagination a.page-link.page-details:hover {
  cursor: default;
  color: #1d1d1d;
  /* background-color: #fff; */
}
ul.pagination a.page-link.page-details span {
  font-weight: 400;
  white-space: nowrap;
}

ul.pagination a.page-link.page-disabled {
  color: lightgrey;
}

ul.pagination a.page-link.page-disabled:hover {
  cursor: default;
  background-color: #f1f5f7;
}
.active {
  width: 22px;
  height: 22px;
  border-radius: 2px;
  background-color: #a9bbbc;
  padding: 2px 7px;
  margin-right: 7px;
  font-size: 14px;
  color: #0e3132;
}
.inactive {
  width: 22px;
  height: 22px;
  border-radius: 2px;
  /* background-color: #f1f5f7; */
  padding: 2px 7px;
  margin-right: 7px;
  font-size: 14px;
  color: #0e3132;
}
.page-data {
  position: relative;
  display: block;
  padding: 0.5rem 0.25rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #0e3132;
}

.page-data:hover {
  z-index: 2;
  color: #0e3132;
  text-decoration: none;
}
.disabled-state {
  opacity: 0.4;
}
