/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2020 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/* @font-face {
  font-family: Archivo;
  src: local(Archivo-Regular),
    url(/path/to/Archivo-Regular.woff2) format("woff2"),
    url(/path/to/Archivo-Regular.woff) format("woff"),
    url(/path/to/Archivo-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Archivo;
  src: local(Archivo-Medium),
    url(/path/to/Archivo-Medium.woff2) format("woff2"),
    url(/path/to/Archivo-Medium.woff) format("woff"),
    url(/path/to/Archivo-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Archivo;
  src: local(Archivo-SemiBold),
    url(/path/to/Archivo-SemiBold.woff2) format("woff2"),
    url(/path/to/Archivo-SemiBold.woff) format("woff"),
    url(/path/to/Archivo-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Archivo;
  src: local(Archivo-Bold),
    url(/path/to/Archivo-Bold.woff2) format("woff2"),
    url(/path/to/Archivo-Bold.woff) format("woff"),
    url(/path/to/Archivo-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: ArchivoBlack;
  src: local(ArchivoBlack-Regular),
    url(/path/to/ArchivoBlack-Regular.woff2) format("woff2"),
    url(/path/to/ArchivoBlack-Regular.woff) format("woff"),
    url(/path/to/ArchivoBlack-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
} */
@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Archivo:wght@400;500;600;700&display=swap&rel=preload');

@font-face {
  font-family:'AvenirLTStd-65';
  src: local(AvenirLTStd-65Medium), url(./Avenir/AvenirLTStd-Medium.otf) format('opentype');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'AvenirLTStd-85';
  src: local(AvenirLTStd-85Heavy), url(./Avenir/AvenirLTStd-Heavy.otf) format('opentype');
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family:'AvenirLTStd-55Roman';
  src: local(AvenirLTStd-Roman), url(./Avenir/AvenirLTStd-Roman.otf) format('opentype');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirLTStd-95';
  src: local(AvenirLTStd-Black), url(./Avenir/AvenirLTStd-Black.otf) format('opentype');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}


@font-face {
  font-family: 'AvenirLTStd-35';
  src: local(AvenirLTStd-Light), url(./Avenir/AvenirLTStd-Light.otf) format("opentype");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
