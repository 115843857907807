.slide-in {
  z-index: 10; /* to position it in front of the other content */
  position: absolute;
  overflow: hidden; /* to prevent scrollbar appearing */
}

.slide-in.from-left {
  left: 0;
}

.slide-in.from-right {
  right: 0;
}

.slide-in-content {
  padding: 5px 20px;
  background-color: #f1f5f7 !important;
  transition: transform 0.5s ease; /* our nice transition */
  height: 100%;
}

.slide-in.from-left .slide-in-content {
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
}

.slide-in.from-right .slide-in-content {
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
}

.slide-in.show .slide-in-content {
  transform: translateX(0);
  -webkit-transform: translateX(0);
  overflow: scroll;
}
.slide-in .modal-dialog {
  min-width: 40%;
  margin: 0;
  right: 0;
  position: fixed;
  top: 0;
  height: 100vh;
}

@media (max-width: 768px) {
  .slide-in .modal-dialog {
    min-width: 100%;
  }
}
