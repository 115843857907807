.skeletonLoader {
  margin-bottom: 8px;
  animation-duration: 30.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: preloadAnim;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #d1dee4 8%, #eeeef2 18%, #d1dee4 33%);
}
@keyframes preloadAnim {
  0% {
    background-position: -769px 0;
  }
  100% {
    background-position: 769px 0;
  }
}

.rotate-180 {
  transform: rotate(180deg);
}
.rotate-0deg {
  transform: rotate(0deg);
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.rotate-180deg {
  transform: rotate(180deg);
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.accordion-background {
  border-radius: 2px;
  background-color: #f1f5f7;
}
.card-accordion {
  margin: 18px;
}
.justify-content-end {
  justify-content: flex-end;
}
.text-align {
  text-align: center;
}
.text-end {
  text-align: end !important;
}
.fs-12 {
  font-size: 12.5px !important;
}
.menuPopup {
  min-width: 100px;
  width: auto !important;
  font-size: 14px;
  height: auto !important;
  /* padding: 8px 0 !important; */
  margin: 0px !important;
  border-radius: 0px;
  border: none;
  background-color: #ffffff;
  z-index: 100;
}
.border-btm {
  border-bottom: 1px solid #f1f5f7;
}
.bg-blue {
  background-color: #2c2f7c;
}
.bg-dark-green {
  background-color: #0e3132;
}
.bg-dark {
  background-color: #000000;
}
.bg-white {
  background-color: #ffffff;
}
.bg-trans {
  background-color: transparent;
}
.bg-white-1 {
  background-color: #f1f5f7;
}
.bg-white-2 {
  background-color: #f7f7f7;
}
.bg-grey {
  background-color: #a9bbbc;
}
.bg-pink {
  background-color: #f9e3d6;
}
.bg-orange {
  background-color: #eca477;
}
.popover {
  min-width: 361px;
  color: #000000;
  z-index: 5 !important;
}
.opacity-7 {
  opacity: 0.7;
}
.opacity-5 {
  opacity: 0.5;
}
.pop-up-info-service {
  position: absolute;
  padding: 16px 16px 38px 16px;
  left: 0px;
  top: 14.5px;
  line-height: 24px;
  letter-spacing: 0.54px;
  min-width: 361px;
  border-radius: 2px;
  border: none;
  border-color: none;
  box-shadow: 1px 2px 4px 0 rgba(184, 184, 184, 0.25);
}
.progress-tracker--vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.progress-tracker {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* margin: 60px auto; */
  padding: 0;
  list-style: none;
}
.progress-tracker--vertical .progress-step {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.progress-step {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  margin: 0;
  padding: 0;
  min-width: 24px;
}
.progress-step .progress-marker {
  color: #fff;
}
.progress-step .progress-last {
  color: #fff;
}
.progress-marker {
  display: block;
  position: relative;
}
.progress-last {
  display: block;
  position: relative;
}
.progress-step.is-complete .progress-last::before,
.progress-step.is-complete .progress-marker::before,
.progress-step.is-progress .progress-marker::before {
  background-color: #27ae60;
}
.progress-step .progress-last::before,
.progress-step .progress-marker::before {
  background-color: #f1f5f7;
  border: 1px solid #27ae60;
}
.progress-last::before,
.progress-marker::before {
  content: attr(data-text);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  z-index: 20;
  width: 12px;
  height: 12px;
  padding-bottom: 2px;
  border-radius: 50%;
  -webkit-transition: background-color, border-color;
  transition: background-color, border-color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  margin-top: 6px;
}
.progress-step.is-complete .progress-marker::after,
.progress-step.is-progress .progress-marker::after {
  background-color: #27ae60;
}

.progress-tracker--vertical .progress-marker::after {
  right: auto;
  top: 12px;
  left: 5px;
  width: 1px;
  height: 100%;
}
.progress-step .progress-marker::after {
  background-color: #27ae60;
}
.progress-marker::after {
  content: '';
  display: block;
  position: absolute;
  /* z-index: -10; */
  top: 10px;
  right: -12px;
  width: 100%;
  height: 4px;
  -webkit-transition: background-color 0.3s, background-position 0.3s;
  transition: background-color 0.3s, background-position 0.3s;
}
.progress-tracker--vertical .progress-text {
  padding: 0 0px 16px 16px;
}
.progress-step .progress-text {
  color: #000000;
  letter-spacing: 0.56px;
}

.progress-text {
  display: block;
  padding: 8px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.progress-title {
  margin-top: 0;
}
.border-red {
  border: solid 1px #f03738 !important;
}
.fade-products {
  background: rgb(0, 0, 0, 0.4);
}
.fade-products > .modal-dialog {
  top: 10.56rem;
}
.fade-profile-modal {
  background: rgb(0, 0, 0, 0.4);
}
.fade-profile-modal > .modal-dialog {
  top: 3.56rem;
}
@media (max-width: 425px) {
  .fade-profile-modal > .modal-dialog {
    left: 10px;
    top: 1.56rem;
  }

  .login-modal > .modal-dialog {
    left: auto;
  }
}
@media (max-width: 375px) {
  .fade-profile-modal > .modal-dialog {
    left: 20px;
    top: 1.56rem;
  }

  .login-modal > .modal-dialog {
    left: auto;
  }
}
@media (max-width: 320px) {
  .fade-profile-modal > .modal-dialog {
    left: 0px;
    top: 1.16rem;
  }
}
.fade-filter-modal {
  background: rgb(11, 11, 9, 0.4);
}
@media (max-width: 768px) {
  .fade-filter-modal > .modal-dialog {
    top: 73px;
  }
}

.fade-buycontact-modal {
  background: rgb(11, 11, 9, 0.4);
}
@media (max-width: 768px) {
  .fade-buycontact-modal > .modal-dialog {
    top: 293px;
  }
}
.modal-dialog {
  max-width: 387px;
}
@media (max-width: 1440px) {
  .modal-dialog {
    max-width: 387px;
  }
}
@media (max-width: 1024px) {
  .modal-dialog {
    max-width: 387px;
  }
}
@media (max-width: 728px) {
  .modal-dialog {
    max-width: 387px;
  }
}
@media (max-width: 570px) {
  .modal-dialog {
    max-width: 387px;
  }
}
@media (max-width: 375px) {
  .modal-dialog {
    max-width: 320px;
  }
}
.modal-large {
  max-width: 100%;
  margin: 0;
}
.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation: load7 1.8s infinite ease-in-out;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
}
.loader {
  color: rgb(240, 159, 134);
  font-size: 10px;
  margin: 32px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
.image-crop-no-image {
  width: 100%;
  height: 100%;
  /* padding-bottom: 100%; */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover !important;
  position: relative;
  /* border-radius: 10px; */
  transition: transform 0.5s ease;
  /* width: 200px;
  height: 200px; */
}
.item {
  -webkit-animation: fadein 5s linear forwards;
  animation: fadein 5s linear forwards;
}
.item-fadeout {
  display: flex;
  -webkit-animation: fadeout 0.3s linear forwards;
  animation: fadeout 0.3s linear forwards;
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.color-black {
  color: #000000 !important;
}
.color-primary {
  color: #0e3132 !important;
}
.tabActive {
  background-color: #0e3132 !important;
  color: #ffffff !important;
}
.tab-head.tabActive:after {
  display: none;
}
.tab-head.tabActive {
  background-color: #0e3132 !important;
  color: #ffffff !important;
}
.disableOption {
  opacity: 0.5;
  pointer-events: none;
  cursor: text !important;
}
.accordion-item {
  transition: 0.3s transform ease-in-out;
}
.search-wp {
  max-width: 980px;
}
.mobile-poweredby {
  margin-top: 67.5px;
  position: relative;
}
.gig-header .mobile-menu #main-nav .new .mobile-poweredby::after {
  content: '';
  position: absolute;
  background-image: url('../assets/images/novare2_1.png');
  background-repeat: no-repeat;
  width: 4.25rem;
  height: 1.25rem;
  right: 1.5rem;
}
.novare-logo {
  margin-top: 67.5px;
  position: relative;
}
.novare-logo::after {
  content: '';
  position: absolute;
  background-image: url('../assets/images/novare2_1.png');
  background-repeat: no-repeat;
  width: 66.7px;
  height: 20px;
  bottom: 6px;
  margin-left: 8px;
}
/* Header CSS Goes Here*/

.gig-header {
  background-color: #0e3132;
}
@media (max-width: 992px) {
  .gig-header {
    padding: 17px 0px;
  }
}
@media (min-width: 992px) {
  .gig-header {
    min-height: 4.5rem;
  }
}

.gig-header .navbar-brand .desktop-logo {
  margin-top: 1rem;
}

.gig-header .navbar-brand .mobile-logo {
  position: relative;
  left: 0px;
}
@media (min-width: 992px) {
  .gig-header .navbar-brand .mobile-logo {
    position: relative;
    left: calc(100% - -58px);
  }
}
@media (max-width: 768px) {
  .navbar-brand {
    display: inline-block;
    padding-top: 7px;
    padding-bottom: 0;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
  }
}
.gig-header .mobile-toggle {
  text-align: end;
}

.gig-header .mobile-toggle .navbar-toggler {
  position: relative;
}

.gig-header .mobile-toggle .navbar-toggler:focus {
  outline: 0;
}

.gig-header .mobile-toggle .navbar-toggler .navbar-toggler-icon {
  cursor: pointer;
  background-image: url('../assets/images/menu.png') !important;
  position: absolute;
  top: -13px;
  left: -33px;
}

.gig-header .mobile-toggle .navbar-toggler.collapsed .navbar-toggler-icon {
  display: block;
}

.gig-header .mobile-toggle .navbar-toggler .navbar-toggler-icon {
  display: none;
}

.gig-header .mobile-toggle .navbar-toggler .new-close {
  background-image: url('../assets/images/close-menu.png') !important;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  vertical-align: middle;
  content: '';
  position: absolute;
  top: -12px;
  left: -26px;
  display: none;
  background-repeat: no-repeat;
}

.gig-header .mobile-toggle .navbar-toggler.collapsed .new-close {
  display: none;
}

.gig-header .mobile-toggle .navbar-toggler .new-close {
  display: block;
}

.gig-header .mobile-menu {
  background-color: #fff;
}

@media (min-width: 992px) {
  .gig-header .mobile-menu {
    background-color: transparent;
  }
}

#main-nav .navbar-collapse .navbar-nav {
  padding: 0;
  min-width: 200px;
}

.gig-header .mobile-menu #main-nav {
  border-bottom: unset;
  padding: 0;
}

@media (min-width: 992px) {
  .gig-header .mobile-menu #main-nav {
    padding: 0.5rem 1rem;
    float: right;
  }
}

.gig-header .mobile-menu #main-nav #navbarNavDropdown {
  margin-left: auto;
  background-color: #0e3132;
  height: 100vh !important;
  position: fixed;
  top: 65px;
  right: 0;
  left: auto;
  max-width: 16.875rem;
  transition-timing-function: ease-in-out;
  box-shadow: 0 0.25rem 0.25rem 0 rgba(40, 40, 40, 0.25);
}

@media (max-width: 768px) {
  .gig-header .mobile-menu #main-nav #navbarNavDropdown {
    right: -39px;
    align-items: baseline;
  }
}
@media (max-width: 570px) {
  .gig-header .mobile-menu #main-nav #navbarNavDropdown {
    right: -15px;
    align-items: baseline;
  }
}

@media (min-width: 768px) {
  .gig-header .mobile-menu #main-nav #navbarNavDropdown.collapse:not(.show) {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .gig-header .mobile-menu #main-nav #navbarNavDropdown {
    background-color: transparent;
    height: unset !important;
    top: unset;
    right: unset !important;
    position: unset;
    max-width: unset;
    display: block !important;
  }
}

@media (min-width: 992px) {
  .gig-header .mobile-menu #main-nav #navbarNavDropdown.collapse:not(.show) {
    display: block !important;
  }
}

.gig-header .mobile-menu #main-nav #navbarNavDropdown #nav-menu-list {
  margin-left: 4rem;
  margin-top: 2rem;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #0e3132;
}

@media (min-width: 768px) {
  .gig-header .mobile-menu #main-nav #navbarNavDropdown #nav-menu-list {
    display: grid !important;
    padding: 0;
  }
}

@media (min-width: 992px) {
  .gig-header .mobile-menu #main-nav #navbarNavDropdown #nav-menu-list {
    margin: unset;
    display: flex !important;
    padding: unset;
    justify-content: flex-end;
  }
}

.gig-header .mobile-menu #main-nav #navbarNavDropdown #nav-menu-list .menu-item {
  margin-right: 0;
}

@media (min-width: 1024px) {
  .gig-header .mobile-menu #main-nav #navbarNavDropdown #nav-menu-list .menu-item {
    margin-right: 0.75rem;
  }
}

@media (min-width: 1040px) {
  .gig-header .mobile-menu #main-nav #navbarNavDropdown #nav-menu-list .menu-item {
    margin-right: 2.75rem;
  }
}

.gig-header .mobile-menu #main-nav #navbarNavDropdown #nav-menu-list .menu-item a {
  color: #ffffff;
  font-size: 1.25rem;
  line-height: 1.6875rem;
  font-weight: 500;
  margin-bottom: 1rem;
}
.gig-header .mobile-menu #main-nav #navbarNavDropdown #nav-menu-list .menu-item a:hover {
  text-decoration: none;
  color: #F5AA82;
}

@media (min-width: 992px) {
  .gig-header .mobile-menu #main-nav #navbarNavDropdown #nav-menu-list .menu-item a {
    font-family: 'Archivo', sans-serif;
    color: #fff;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: normal;
    margin-bottom: unset;
  }
}

.gig-header .mobile-menu #main-nav .new {
  position: absolute;
  z-index: 1500;
  margin-top: 0%;
  margin-left: calc(100% - 186px);
  top: calc(100% + 362px);
}

@media (min-width: 768px) {
  .gig-header .mobile-menu #main-nav .new {
    margin-left: calc(100% - 189px);
  }
}

@media (min-width: 992px) {
  .gig-header .mobile-menu #main-nav .new {
    position: unset;
    margin-top: unset;
    margin-left: auto;
  }
}

.gig-header .mobile-menu #main-nav .new .sign-gig {
  margin-bottom: 2.1875rem;
  width: 10.625rem;
}

@media (min-width: 992px) {
  .gig-header .mobile-menu #main-nav .new .sign-gig {
    margin-bottom: unset;
  }
}

.gig-header .mobile-menu #main-nav .new .sign-gig .mblogout {
  border-top: 0.0625rem solid rgba(40, 40, 40, 0.25);
}

.gig-header .mobile-menu #main-nav .new .sign-gig .mblogout .myacc-txt {
  color: #0e3132;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  position: relative;
}

@media (min-width: 992px) {
  .gig-header .mobile-menu #main-nav .new .sign-gig .mblogout .myacc-txt {
    color: #fff;
  }
}

.gig-header .mobile-menu #main-nav .new .sign-gig .mblogout .myacc-txt::before {
  content: '';
  position: absolute;
  background-image: url('../assets/images/Group 34.png');
  background-repeat: no-repeat;
  width: 1.4375rem;
  height: 1.8125rem;
  left: -1.8125rem;
}
.signIn-btn {
  border: 0.0625rem solid #0e3132;
  border-radius: 0.125rem;
  text-align: center;
  padding: 0.625rem 0;
  width: 7.3125rem;
  font-size: 0.875rem;
  font-weight: 500;
}
.gig-header .mobile-menu #main-nav .new .sign-gig .login-btn {
  border: 0.0625rem solid #0e3132;
  border-radius: 0.125rem;
  text-align: center;
  padding: 0.625rem 0;
  width: 7.3125rem;
  font-size: 0.875rem;
  font-weight: 500;
}
.gig-header .mobile-menu #main-nav .new .login-btn .login-btn:hover {
  background-color: #000000;
}
@media (min-width: 992px) {
  .gig-header .mobile-menu #main-nav .new .login-btn .login-btn {
    width: 6.25rem;
    border: 0.0625rem solid #fff;
    color: #fff;
  }
}

.gig-header .mobile-menu #main-nav .new .login-btn .login-btn a {
  color: #0e3132;
}

@media (min-width: 992px) {
  .gig-header .mobile-menu #main-nav .new .login-btn .login-btn a {
    color: #fff;
  }
}

.gig-header .mobile-menu #main-nav .new .login-gig .logout-txt {
  color: #0e3132;
  font-size: 1.125rem;
  line-height: 1.6875rem;
}

@media (min-width: 992px) {
  .gig-header .mobile-menu #main-nav .new .login-gig .logout-txt {
    color: #fff;
  }
}

.gig-header .mobile-menu #main-nav .new .signup-btn .signup-btn {
  background-color: #eca477;
  border-radius: 0.125rem;
  text-align: center;
  padding: 0.625rem 0;
  width: 7.3125rem;
  height: 2.6875rem;
  font-size: 0.875rem;
  font-weight: 500;
}
.gig-header .mobile-menu #main-nav .new .signup-btn .signup-btn:hover {
  background-color: #cf8b60;
}
@media (min-width: 992px) {
  .gig-header .mobile-menu #main-nav .new .signup-btn .signup-btn {
    width: 6.25rem;
    color: #0e3132;
  }
}

.gig-header .mobile-menu #main-nav .new .signup-btn .signup-btn a {
  color: #0e3132;
}

.gig-header .mobile-menu #main-nav .new .mobile-poweredby {
  margin-top: 67.5px;
  position: relative;
  width: 100%;
  padding-left: 7%;
}

.gig-header .mobile-menu #main-nav .new .poweredby::after {
  content: '';
  position: absolute;
  background-image: url('../assets/images/novare2_1.png');
  background-repeat: no-repeat;
  width: 4.25rem;
  height: 1.25rem;
  right: 1.5rem;
  left: calc(100% + -156px);
}
#nav-menu-list li:first-child {
  display: block;
}

.gig-header
  .mobile-menu
  #main-nav
  #navbarNavDropdown
  #nav-menu-list
  .menu-item.menu-item-has-children
  .dropdown-menu
  li
  a {
  font-size: 18px;
}
.gig-header
  .mobile-menu
  #main-nav
  #navbarNavDropdown
  #nav-menu-list
  .menu-item.menu-item-has-children
  .dropdown-menu
  li
  a:hover {
  background-color: rgba(14, 49, 50, 0.3);
  color: #000000;
}

.gig-header .mobile-menu #main-nav #navbarNavDropdown #nav-menu-list .menu-item.menu-item-has-children > a:after {
  content: url('../assets/images/ArrowDown.svg');
  position: absolute;
  top: 11px;
  right: 32px;
  transform: rotate(0deg);
  transition: 0.007s ease-in-out;
  border: 0px;
}
.gig-header .mobile-menu #main-nav #navbarNavDropdown #nav-menu-list .menu-item.menu-item-has-children.show > a:after {
  position: absolute;
  content: url('../assets/images/ArrowDown.svg');
  top: 5px;
  right: 32px;
  transform: rotate(180deg);
  transition: transform 0.007s ease-in-out;
  border: 0px;
}

.gig-header
  .mobile-menu
  #main-nav
  #navbarNavDropdown
  #nav-menu-list
  .menu-item.menu-item-has-children
  .dropdown-menu.show:before {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0.78125rem 0.625rem 0.78125rem;
  border-color: transparent transparent #eca477 transparent;
  top: -9px;
  right: 12%;
}
.gig-header .mobile-menu #main-nav #navbarNavDropdown #nav-menu-list .menu-item.menu-item-has-children .dropdown-menu {
  background: #eca477 !important;
  width: 116%;
  left: -32px;
  border-radius: 0px;
  padding: 16px 0px 24px 0px;
  margin: 0;
  transform: rotate(0deg);
  position: relative;
}

.gig-header .mobile-menu #main-nav #navbarNavDropdown #nav-menu-list {
  margin-left: 1rem !important;
  margin-top: 2.1875rem !important;
  padding-right: 2rem;
  text-align: right;
}

.dropdown-toggle::after {
  width: auto !important;
  height: auto !important;
}

@media (min-width: 992px) {
  .gig-header .mobile-menu #main-nav #navbarNavDropdown #nav-menu-list .menu-item.menu-item-has-children > a:after {
    content: url('../assets/images/White-chevron-bottom.svg');
    transform: rotate(0deg);
    right: -13px;
    top: 11px;
  }

  .gig-header
    .mobile-menu
    #main-nav
    #navbarNavDropdown
    #nav-menu-list
    .menu-item.menu-item-has-children.show
    > a:after {
    position: absolute;
    content: url('../assets/images/White-chevron-bottom.svg');
    transform: rotate(180deg);
    right: -13px;
    top: 5px;
  }

  .gig-header .mobile-menu #main-nav #navbarNavDropdown #nav-menu-list {
    margin-left: 0 !important;
    margin-top: 0 !important;
  }

  .gig-header
    .mobile-menu
    #main-nav
    #navbarNavDropdown
    #nav-menu-list
    .menu-item.menu-item-has-children
    .header-dropdown {
    left: -32px !important;
    width: 190px !important;
    margin-top: 0 !important;
  }

  .gig-header
    .mobile-menu
    #main-nav
    #navbarNavDropdown
    #nav-menu-list
    .menu-item.menu-item-has-children
    .dropdown-menu
    li
    a:hover {
    background-color: rgba(14, 49, 50, 0.3);
    color: #000000;
  }

  .gig-header
    .mobile-menu
    #main-nav
    #navbarNavDropdown
    #nav-menu-list
    .menu-item.menu-item-has-children
    .dropdown-menu
    li {
    margin: 0px;
  }

  .gig-header
    .mobile-menu
    #main-nav
    #navbarNavDropdown
    #nav-menu-list
    .menu-item.menu-item-has-children
    .dropdown-menu {
    left: -60px;
    position: absolute;
    width: 190px;
    margin-top: 20px;
    padding: 16px 0px 24px 0px;
  }
  .gig-header
    .mobile-menu
    #main-nav
    #navbarNavDropdown
    #nav-menu-list
    .menu-item.menu-item-has-children
    .dropdown-menu
    li
    a {
    display: block;
    width: 100%;
    padding: 8px 0px 8px 24px;
    color: #000000;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    margin-left: 0;
  }
  .gig-header
    .mobile-menu
    #main-nav
    #navbarNavDropdown
    #nav-menu-list
    .menu-item.menu-item-has-children
    .dropdown-menu.show:before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0.78125rem 0.625rem 0.78125rem;
    border-color: transparent transparent #eca477 transparent;
    top: -9px;
    right: 12%;
  }
}

@media (min-width: 992px) {
  #nav-menu-list li:first-child {
    display: none;
  }
}
/* Footer CSS Goes Here*/

#gig-footer {
  background-color: #0e3132;
  position: absolute;
  width: 100%;
  color: #eca477;
  padding-bottom: 43px;
}

#gig-footer p {
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  letter-spacing: 0.035rem;
  font-family: 'AvenirLTStd-55Roman', sans-serif;
  font-weight: 400;
}

#gig-footer .gig-logo {
  margin: 3rem 0 6px;
  width: 18rem;
}

@media (min-width: 992px) {
  #gig-footer {
    padding-bottom: 0;
  }
  #gig-footer .gig-logo {
    margin: 2.75rem 0 3.1875rem 0;
    width: auto;
  }
}

#gig-footer .footer-menu-wrapper #footer-menu {
  display: block;
  margin-left: -0.9375rem;
}

@media (min-width: 992px) {
  #gig-footer .footer-menu-wrapper #footer-menu {
    margin-left: -0.8125rem;
    margin-bottom: 30px;
    display: flex;
  }
}

#gig-footer .footer-menu-wrapper #footer-menu li {
  font-size: 0.875rem;
  line-height: 1.3125rem;
  font-weight: 500;
  margin-bottom: 0.625rem;
}

@media (min-width: 992px) {
  #gig-footer .footer-menu-wrapper #footer-menu li {
    margin-right: 2.3125rem;
    margin-bottom: 0;
  }
}

#gig-footer .footer-menu-wrapper #footer-menu li a {
  color: #eca477;
  text-decoration: underline;
  font-family: 'Archivo', sans-serif;
  font-weight: 500;
  letter-spacing: 0.56px;
  line-height: 21px;
}

#gig-footer .language-part ul {
  display: flex;
  list-style-type: none;
  margin: 0.875rem 0 1.4375rem -1.5625rem;
}

@media (min-width: 992px) {
  #gig-footer .language-part ul {
    margin: 0 0 0 0px;
  }
}

#gig-footer .language-part ul li {
  height: 2.125rem;
  width: 3.375rem;
  border: solid 1px #eca477;
  text-align: center;
  font-weight: 500;
  line-height: 1.5rem;
  cursor: pointer;
  padding: 0.1875rem 0;
  text-transform: lowercase;
  opacity: 0.3;
}

#gig-footer .language-part ul li::first-letter {
  text-transform: uppercase;
}

#gig-footer .language-part ul li.highlighted-menu {
  background-color: #eca477;
  height: 2.125rem;
  width: 3.375rem;
  opacity: 1.3;
}

#gig-footer .language-part ul li.highlighted-menu a {
  color: #0d0f3c;
}

#gig-footer .language-part ul li a {
  color: #eca477;
  padding: 0.625rem;
}

#gig-footer .detail-section {
  border-top: none;
}

#gig-footer .detail-section-mobile {
  border-top: 0.0625rem solid #eca477;
}

@media (min-width: 992px) {
  #gig-footer .detail-section {
    padding-bottom: 1.5rem;
    border-top: 0.0625rem solid #eca477;
  }
}

#gig-footer .detail-section .final-part {
  padding-bottom: 3.5625rem;
}

@media (min-width: 992px) {
  #gig-footer .detail-section .final-part {
    padding-bottom: 1rem;
  }
}

#gig-footer .detail-section .final-part .gig-address {
  margin-bottom: 1.5rem;
}

@media (min-width: 992px) {
  #gig-footer .detail-section .final-part .gig-address {
    margin-bottom: 0px;
    font-family: 'Archivo', sans-serif;
  }
}

#gig-footer .detail-section .final-part .address-header {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}

@media (min-width: 992px) {
  #gig-footer .detail-section .final-part .address-header {
    font-size: 18px;
    font-weight: 750;
  }
}

#gig-footer .detail-section .final-part p {
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 5px;
  color: #F5AA82;
}

@media (max-width: 768px) {
  #gig-footer .detail-section .final-part p {
    font-size: 14px;
  }

  #gig-footer .detail-section-mobile .address-headers {
    font-size: 16px;
  }

  #gig-footer .follow-us {
    font-size: 16px;
  }
}

#gig-footer .detail-section .final-part .address-headers:hover{
    color: #ffffff;
    text-decoration: none;
  }

  #gig-footer .detail-section .final-part a:hover{
    text-decoration: none;
  }

@media (min-width: 992px) {
  #gig-footer .detail-section .final-part .address-headers {
    text-transform: capitalize;
  }
}

#gig-footer .detail-section .final-part .upcase-header {
  text-transform: none;
}

#gig-footer .detail-section .final-part .social-media {
  font-size: 0.875rem;
  line-height: 1.3125rem;
  color: #eca477;
  font-family: 'AvenirLTStd-55Roman', sans-serif;
  text-decoration: underline;
  font-weight: 500;
  display: inline-block;
}

#gig-footer .social-image .facebook {
  background-image: url('../assets/icons/FacebookIcon.svg');
  width: 1.5625rem;
  height: 1.5625rem;
  display: inline-block;
}

#gig-footer .social-image .instagram {
  background-image: url('../assets/icons/bxl-instagram-alt.svg');
  width: 1.5625rem;
  height: 1.5625rem;
  display: inline-block;
}

#gig-footer .social-image .linkedin {
  background-image: url('../assets/icons/bxl-linkedin.svg');
  width: 1.5625rem;
  height: 1.5625rem;
  display: inline-block;
}

#gig-footer .social-image .facebook:hover {
  background-image: url('../assets/images/facebook-white.svg');
}

#gig-footer .social-image .instagram:hover {
  background-image: url('../assets/images/instagram-white.svg');
}

#gig-footer .social-image .linkedin:hover {
  background-image: url('../assets/images/linkedin-white.svg');
}

@media (min-width: 992px) {
  #nav-menu-list li:first-child,
  #nav-menu-list li:nth-child(7) {
    display: none;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.scroll {
  overflow: auto;
}
.scroll::-webkit-scrollbar {
  width: 8px;
  height: 137px;
}

.scroll::-webkit-scrollbar-track {
  border-radius: 4px;
  background: #ffffff;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #a9bbbc;
}
.w-508 {
  width: 508px !important;
}
.top-12-rem {
  top: 12rem !important;
}
.d-contents {
  display: contents !important;
}
.fs-14 {
  font-size: 14px !important;
}
.arrow-down-sort {
  transform: rotate(270deg);
  margin-left: 8px;
  vertical-align: text-bottom;
}

.arrow-up-sort {
  transform: rotate(90deg);
  margin-left: 8px;
  vertical-align: text-bottom;
}
.close:focus {
  outline: none !important;
}
.font-size-10 {
  font-size: 10px !important;
}
.mt-14 {
  margin-top: 14px;
}
.d-inline-table {
  display: inline-table !important;
}
.line {
  position: absolute;
  height: 100%;
  width: 1px;
  z-index: 0;
  top: 6px;
  left: 86px;
  background-color: #0E3132;
}
@media (max-width: 600px) {
  .line {
    left: -18px;
  }
}
@media (max-width: 768px) {
  .line {
    left: -18px;
  }
}
.line-dot-wrapper .lines:last-child .line {
  height: 0;
}
.knob {
  align-self: center;
  width: 7px;
  aspect-ratio: 1;
  z-index: 1;
  background: #0E3132;
  border-radius: 50%;
  position: absolute;
  top: 6px;
  left: 83px;
}
@media (max-width: 600px) {
  .knob {
    left: -5%;
  }
}
@media (max-width: 768px) {
  .knob {
    left: -21px;
  }
}

.gig-header .mobile-menu #main-nav .new .login-gig a:hover {
  color: #F5AA82 !important;
}


@media (max-width: 768px) {
  .mobile-menu #loginSectionDisplay {
   position: relative;
   top: 30px;
  }

  .gig-header {
    position: fixed;
    z-index: 9999;
    width: 100%;
  }
}
